/* CSS Document */
:root {
  --default: #2c2c2c;
  --primary: #412f88;
  --primary-dark: #050033;
  --secondary: #d3212d;
  --gray: #c8c8c8;
  --black: #000000;
  --green: #32da08;
  --red: #ff0000;
  --lightgray: #f9f8f7;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
html,
body,
.App,
main {
  min-height: 100%;
}
body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: var(--default);
  font-size: 0.8rem; /* background-color: #fbded6;*/
  background-color: #ebebeb;
}
img {
  max-width: 100%;
  height: auto;
}
strong,
b,
.bold {
  font-weight: 700;
}
.medium {
  font-weight: 500 !important;
}
.bolder {
  font-weight: 900 !important;
}
a,
button,
input[type="submit"] {
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
a,
.btn-link,
.text-red {
  color: var(--primary);
}
a:hover,
a:focus,
button,
.btn {
  text-decoration: none;
}
a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary) !important;
}
iframe {
  width: 100% !important;
}
small,
.small {
  font-weight: inherit;
}
sub,
sup {
  font-size: 50%;
}
hr {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.error {
  color: var(--red);
  font-size: 13px;
}
.row-gap {
  margin: 0 -5px;
}
.row-gap [class*="col-"],
.row-gap .col {
  padding: 0 5px;
}
.form-control {
  font-weight: 400;
  color: var(--black);
  height: 50px;
  font-size: 0.8rem;
  border-radius: 40px;
  padding: 0 1.5rem;
}
.form-control:focus {
  background-color: var(--white);
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: rgba(255, 228, 229, 0.7);
  border-color: rgba(255, 228, 229, 0.7);
  opacity: 1;
  color: #696969;
}
.form-control::placeholder {
  color: var(--gray);
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: var(--black);
}
.form-control::-ms-input-placeholder {
  color: var(--black);
}
textarea.form-control {
  min-height: 80px;
  padding: 0.7rem 1rem;
  border-radius: 20px;
}
textarea.h-large {
  min-height: 150px;
}

select.form-control,
select,
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: 0 !important;
}
select:focus,
option:focus {
  outline: 0 !important;
}
select[multiple].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding-right: 25px;
  height: auto;
}
select::-ms-expand {
  display: none;
}
.form-group {
  margin-bottom: 1.2rem;
}

.btn {
  padding-left: 1.5rem;
  border-radius: 40px;
  padding: 0 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  height: 38px;
  line-height: 36px;
}
.btn-lg {
  font-size: 0.9rem;
  height: 50px;
  line-height: 48px;
}
.btn-sm {
  font-size: 0.7rem;
  padding: 0 1.2rem;
  height: 30px;
  line-height: 28px;
}
.btn-icon {
  padding: 0.4rem;
  border-radius: 0.25rem;
  background: none;
  border: none;
}
.btn-icon img {
  max-height: 15px;
}
.btn-icon:hover {
  background: #fff;
}
.btn:hover {
  box-shadow: 0 0 0 0.1rem rgb(23 70 158 / 20%);
}
.btn-outline-default,
.bootstrap-select > .btn.btn-outline-default,
.btn-default.disabled,
.btn-default:disabled {
  border-color: var(--black);
  color: var(--black);
}
.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default:active,
.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.show > .btn-outline-default.dropdown-toggle {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary,
.bootstrap-select > .btn.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  border-color: var(--primary);
  color: var(--primary);
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.btn-outline-secondary,
.bootstrap-select > .btn.btn-outline-secondary,
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  border-color: var(--primary);
  color: var(--default);
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.btn-outline-primary:hover .icon-fill svg path,
.btn-outline-primary:focus .icon-fill svg path {
  fill: var(--white);
}
.btn-outline-primary:hover .icon-outline svg path,
.btn-outline-primary:focus .icon-outline svg path {
  stroke: var(--white);
}
.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--white);
}
.btn-default,
.btn-default.disabled,
.btn-default:disabled {
  background-color: var(--gray);
  border-color: var(--gray);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.border-light {
  border-color: #ccc !important;
}
.text-muted {
  color: #8b8b8b !important;
}
.regular {
  font-weight: 400 !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: var(--font-secondary);
}
.h1,
h1 {
  font-size: 3.5rem;
}
.h2,
h2 {
  font-size: 2.4rem;
}
.h3,
h3 {
  font-size: 1.8rem;
}
.h4,
h4 {
  font-size: 1.1rem;
}
.h5,
h5 {
  font-size: 0.9rem;
}
hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-color: #eee;
}
.bg-default {
  background-color: var(--default);
}
.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-dark {
  background-color: var(--primary) !important;
}
.bg-gray {
  background-color: var(--lightgray) !important;
}
.bg-lightgray {
  background-color: #f8f8f8 !important;
}
.text-default {
  color: var(--default) !important;
}
.text-primary {
  color: var(--primary) !important;
}
.text-primary-dark {
  color: var(--primary-dark) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}
.text-black {
  color: var(--black) !important;
}
.text-red {
  color: var(--red) !important;
}
.green {
  color: var(--green) !important;
}
.text-large {
  font-size: 1rem;
}
@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.relative {
  position: relative;
}
.bootstrap-select > .btn {
  background-color: var(--white);
  border: 1px solid #ced4da;
  height: 50px;
  border-radius: 40px;
  line-height: 48px;
  padding: 0 1rem;
  font-weight: 400;
}
.bootstrap-select .dropdown-toggle::after {
  font-size: 1.2rem;
}
.bootstrap-select > .btn-gray {
  background-color: #f1f5f9;
  border-color: #f1f5f9;
}
.bootstrap-select.border-0 > .btn {
  border: none;
}
.bootstrap-select > .btn:hover {
  box-shadow: none;
}
.bootstrap-select > .btn:focus,
.bootstrap-select.show > .btn-light.dropdown-toggle,
.bootstrap-select.show > .btn-light.dropdown-toggle:active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  border-color: var(--primary);
}
.bootstrap-select .no-results {
  padding: 1rem;
  margin: 0;
  font-size: 0.85rem;
  background: var(--white);
  color: var(--default);
}
.bootstrap-select > .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary {
  color: var(--white);
}
.bootstrap-select > .btn-secondary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary {
  color: var(--white);
}
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: 0 !important;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #777;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown-menu {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  padding: 0.2rem 0;
  margin: 0;
}
.dropdown-item-text {
  color: var(--primary);
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 700;
}
.dropdown-item,
.dropdown-menu a {
  color: var(--default);
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  white-space: inherit;
  display: block;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: rgba(248, 248, 252, 0.5);
  color: #1d2c48;
}
.dropdown-item.active,
.dropdown-item.active:hover {
  background: none;
  font-weight: 600;
  color: var(--primary);
}
.bootstrap-select .dropdown-header {
  color: var(--default);
  font-weight: 500;
}
.bs-actionsbox,
.bs-donebutton,
.bs-searchbox {
  padding: 10px;
}
.bootstrap-select.form-control-lg .dropdown-toggle {
  height: 45px;
}
.bootstrap-select.form-control-lg .dropdown-toggle .filter-option-inner-inner {
  padding: 3px 0;
}
.bootstrap-select.form-control-lg .dropdown-toggle::after {
  width: auto;
  margin-top: -18px;
}
.btn-light,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: var(--default);
  background-color: var(--white);
  border-color: var(--white);
}
.btn-light:hover {
  background-color: var(--white);
}
.btn-radio input[type="radio"] + label,
.btn-checkbox input[type="checkbox"] + label {
  position: relative;
  padding: 2px 0 0 30px;
  margin: 0;
  cursor: pointer;
  display: inline-block;
  color: var(--default);
  font-weight: normal;
  min-height: 20px;
  user-select: none;
}
.btn-radio input[type="radio"],
.btn-checkbox input[type="checkbox"] {
  display: none;
}
.btn-checkbox input[type="checkbox"] + label:before {
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 2px;
  background: transparent;
}
.btn-checkbox input[type="checkbox"]:checked + label:before {
  background: var(--secondary);
  border-color: var(--secondary);
}
.btn-checkbox input[type="checkbox"] + label:after,
.dropdown-menu
  .inner[aria-multiselectable="true"]
  .dropdown-item
  span.check-mark:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 4px;
  width: 12px;
  height: 6px;
  border: solid transparent;
  border-width: 0 0 2px 2px;
  line-height: 0;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -ms-transform: rotate(-45deg) scale(2);
  -webkit-transform: rotate(-45deg) scale(2);
  transform: rotate(-70deg) scale(2);
}
.btn-checkbox input[type="checkbox"]:checked + label:after,
.dropdown-menu
  .inner[aria-multiselectable="true"]
  .dropdown-item.selected
  span.check-mark:after {
  -ms-transform: rotate(-45deg) scale(1);
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
  border-color: var(--white);
}
.btn-radio input[type="radio"] + label:before {
  border: 2px solid #ced4da;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 2px;
  border-radius: 50%;
}
.btn-radio input[type="radio"]:checked + label:before {
  border-color: var(--secondary);
}
.btn-radio input[type="radio"] + label:after {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 4px;
  line-height: 0;
  background: var(--secondary);
  content: "";
  border-radius: 50%;
  -ms-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.btn-radio input[type="radio"]:checked + label:after {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.btn-radio input[type="radio"]:disabled + label,
.btn-checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.5;
}
.checkbox-inline,
.radio-inline {
  padding: 0 20px 15px 0;
}
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 0;
}

.dropdown-menu
  .inner[aria-multiselectable="true"]
  .dropdown-item.selected
  span.check-mark::after {
  border-color: var(--black);
}
.dropdown-menu
  .inner[aria-multiselectable="true"]
  .dropdown-item
  span.check-mark::after {
  left: -10px;
}
#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background: rgb(246, 45, 45);
  background: linear-gradient(
    90deg,
    rgb(110, 96, 136) 0%,
    rgba(246, 45, 45, 1) 100%
  );
  box-shadow: 0 2px 4px rgb(15, 34, 58, 0.1);
  height: 80px;
  transition-duration: 0.4s;
}
.navbar-brand-logo {
  display: block;
  color: var(--white);
  max-width: 200px;
}
#navbar .nav-item {
  margin-right: 2rem;
}
#navbar .nav-link {
  line-height: 80px;
  color: var(--white);
  padding: 0;
  font-size: 1rem;
  position: relative;
}
#navbar .nav-link:before {
  content: "";
  width: 0;
  height: 4px;
  background: var(--white);
  bottom: 0;
  left: 0;
  position: absolute;
  transition-duration: 0.4s;
}
#navbar .nav-link:hover:before,
#navbar .nav-link.active:before {
  width: 100%;
}
.header-notify .nav-link .badge {
  background: #ef7777;
  padding: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  font-size: 70%;
  color: var(--white);
  line-height: 18px;
  position: absolute;
  top: 16px;
  right: 8px;
}
.avtar-img {
  height: 50px;
  width: 50px;
  border: solid 1px var(--white);
  background: var(--white);
}
.avtar-title {
  font-size: 1rem;
  color: var(--default);
  font-weight: 600;
  max-width: 100px;
}
.avtar-title small {
  opacity: 0.7;
  font-weight: 400;
}
.col-avtar .rounded-circle {
  max-width: 200px;
}
#footer {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.login-wrapper {
  min-height: 100%;
}
#root {
  height: 100%;
}
.login-form {
  max-width: 420px;
  margin: 1rem auto;
}
.login-logo {
  max-width: 200px;
}
.brand-logo {
  max-width: 420px;
  height: 100px;
  align-items: center;
  display: flex;
  margin: 0 auto;
}
.login-form .form-control {
  padding: 0 1.5rem 0 3rem;
}
.input-user .form-control {
  background: url(../../assets/img/user-icon.svg) no-repeat 1.2rem center
    var(--white);
  background-size: 16px;
}
.input-pass .form-control {
  background: url(../../assets/img/lock-icon.svg) no-repeat 1.2rem center
    var(--white);
  background-size: 16px;
}
.form-control.is-currency {
  background-image: url(../../assets/img/dollar_sign_icon.svg);
  background-position: 1.2rem center;
  background-repeat: no-repeat;
  padding-left: 3rem;
  background-size: 20px;
}
.hp-banner .slide-text {
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 0;
}
.hp-banner .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -40px;
  left: 0;
}
.hp-banner .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.card {
  border-radius: 0.8rem;
  border: none;
  box-shadow: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
}
.card-header:first-child {
  border-radius: 0.8rem 0.8rem 0 0;
}
.dropdown .dropdown-menu {
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  top: 100% !important;
}
.dropdown .dropdown-menu-end[style],
.dropdown .dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
}
.dropdown-lg {
  width: 320px;
}
.datepicker.dropdown-menu {
  font-size: 0.8rem;
  padding: 0.7rem;
}
.datepicker td,
.datepicker th {
  height: 25px;
  width: 25px;
}
.input-daterange input:first-child,
.input-daterange input:last-child {
  border-radius: 0.25rem;
}
.date-input {
  background: url("../../assets/img/calendar-icon.svg") no-repeat
    calc(100% - 20px) center var(--white);
  background-size: 16px;
  border-radius: 40px !important;
}

.dashboard-content {
  padding: 100px 0 40px;
}
.table thead {
  background: #f1f5f9;
  border: none;
  color: var(--black);
  white-space: nowrap;
}
.table th,
.table td {
  border: none;
  padding: 1rem 1.25rem;
}
.table td {
  padding: 0.3rem 1.25rem;
  vertical-align: middle;
}
.v-align-middle td {
  vertical-align: middle;
}
.table td.nowrap {
  white-space: nowrap;
}
.table-border td {
  border-bottom: solid 1px #f1f5f9;
}
.v-aligm-middle.table td {
  vertical-align: middle;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #faf9f9;
}
.table-hover tbody tr:hover {
  background-color: rgba(255, 228, 229, 0.7);
}
.v-middle.table td {
  vertical-align: middle;
}
.table .fa-sort-asc {
  vertical-align: bottom;
}
.table .fa-sort-desc {
  vertical-align: top;
}
.col-sort {
  cursor: pointer;
  user-select: none;
}
.modal-content {
  padding: 1rem;
}
.modal-header {
  margin: 0 1rem;
  padding: 1rem 0;
  border-bottom-width: 2px;
}
.modal-body {
  padding: 2rem 1rem 1rem;
}
.modal-header .close {
  padding: 0.7rem;
  outline: none;
}
.modal-header .close span {
  width: 26px;
  height: 26px;
  display: inline-block;
  line-height: 22px;
  border: solid 2px var(--black);
  border-radius: 100%;
}

.nav-pills.nav-sm .nav-link {
  padding: 0 1.2rem;
  height: 30px;
  line-height: 30px;
}
.nav-pills .nav-link {
  color: var(--black);
}
.nav-pills .nav-link.active {
  background: var(--primary);
}
.btn-icon-sm {
  line-height: normal;
  height: auto;
}
.btn-icon {
  min-width: 40px;
  width: 40px;
  display: inline-block;
}
.dropdown-toggle:after {
  margin-left: 0.4em;
  vertical-align: 0.2em;
}
@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(-10px);
  }
}
.hover-shadow {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hover-shadow:hover {
  box-shadow: 0 20px 35px -20px #000;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.card-counter .title .fa,
.arrow-circle {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #eee;
  border-radius: 100%;
  font-size: 1.2rem;
  text-indent: 2px;
}
.card-counter {
  border-bottom: solid 2px var(--secondary);
}
.card-counter h4 {
  font-size: 0.8rem;
  font-weight: 500;
}
.card-counter .h1 {
  font-size: 2rem;
  letter-spacing: -1px;
}
.card-counter .h1.is-currency {
  background: url(../../assets/img/dollar_sign_icon.svg) no-repeat 0 5px;
  padding-left: 1rem;
  background-size: 16px;
}
.legend-dots span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #f2f2f2;
}
.legend-title {
  line-height: 1;
}
.datepicker {
  padding: 0 !important;
}

.progress,
.progress-bar {
  height: 8px;
  border-radius: 10px;
}
.top-performer .progress-bar {
  background-color: var(--primary);
}
.bottom-performer .progress-bar {
  background-color: var(--secondary);
}
.top-performer .col-progress {
  color: var(--primary);
}
.bottom-performer .col-progress {
  color: var(--secondary);
}

.col-author {
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  max-width: 35%;
}
.col-number {
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 100px;
  text-align: center !important;
}
.author-img {
  min-width: 45px;
  min-height: 45px;
  overflow: hidden;
  position: relative;
  background-color: var(--gray);
}
.author-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.performer-head {
  margin: 0 -1.5rem;
  padding: 0 1.5rem;
}
.filter-nav .btn-radio {
  border-bottom: solid 1px var(--gray);
}
.filter-nav .btn-radio input[type="radio"] + label {
  padding: 0 0 0.5rem 0;
  top: 2px;
  margin-right: 1.5rem;
  opacity: 0.7;
}
.filter-nav .btn-radio:last-child input[type="radio"] + label {
  margin-right: 0;
}
.filter-nav .btn-radio input[type="radio"]:checked + label {
  border-bottom: solid 3px var(--secondary);
  opacity: 1;
}
.filter-nav .btn-radio input[type="radio"] + label:before,
.filter-nav .btn-radio input[type="radio"] + label:after {
  display: none;
}

.switch-btn input[type="checkbox"] + label {
  border-radius: 40px;
  width: 50px;
  height: 25px;
  background: var(--secondary);
  display: inline-block;
  padding: 0;
}
.switch-btn input[type="checkbox"] + label:after {
  display: none;
}
.switch-btn input[type="checkbox"] + label:before {
  border-radius: 100%;
  width: 17px;
  height: 17px;
  background: var(--white);
  border: none;
  left: 5px;
  top: 4px;
  transition-duration: 0.4s;
}
.switch-btn input[type="checkbox"]:checked + label {
  background: var(--primary);
}
.switch-btn input[type="checkbox"]:checked + label:before {
  background: var(--white);
  border: none;
  left: 28px;
  top: 4px;
}

/***.dashboard-table .btn-icon, .dashboard-table .btn-icon { opacity: 0;} */
.dashboard-table tbody tr:hover .btn-icon {
  opacity: 1;
}
.search-btn {
  min-width: 50px;
  height: 50px;
  border-radius: 100%;
  border: none;
  padding: 0;
  text-align: center;
  background: var(--white);
  cursor: pointer;
  margin-top: 15px;
}
.search-btn:hover {
  background: var(--white);
}
.select-filter {
  position: relative;
}
.select-filter .bootstrap-select > .btn,
.filter-group .form-control,
.filter-group
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 200px;
}

/*.select-filter .bootstrap-select > .btn, .filter-group .form-control { background: none !important; font-weight: 700; color: var(--primary-dark); font-size: 0.85rem; border: none; line-height: 1.4; padding:0.3rem 0; box-shadow: none !important; height: auto; outline: none;}
.select-filter .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn), .filter-group .form-control { width: 120px;}
.select-filter.filter-email .form-control {width: 165px;}
.filter-group .dropdown-toggle:after {font-size: 1rem; position: absolute; top:14px; right: 0}
.filter-group .datepicker .form-control { cursor: pointer}
.filter-group .datepicker .dropdown-toggle:after {top:34px; right: 0}*/

.btn-back {
  font-size: 1.4rem;
  display: inline-block;
  padding: 1rem 1rem 1rem 0;
  color: var(--primary-dark);
}

.datepicker.dropdown-menu {
  font-size: 0.8rem;
  padding: 0.7rem;
}
.datepicker td,
.datepicker th {
  height: 25px;
}
.input-daterange input:first-child,
.input-daterange input:last-child {
  border-radius: 0.25rem;
}

.collapse-btn {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #eee;
  border-radius: 100%;
  font-size: 1.2rem;
  text-indent: 0px;
  border: none;
  outline: 0 !important;
  cursor: pointer;
  padding: 0;
  transition-duration: 0.4s;
  transform: rotate(180deg);
}
.collapse-btn.collapsed {
  transform: rotate(0);
}
.accordion-strip .card,
.accordion-strip .card .card-header {
  border-radius: 0;
  transition-duration: 0.4s;
  box-shadow: none;
}

.accordion-strip > .card > .card-header {
  margin-bottom: 0 !important;
}

.role-group {
  padding: 1.5rem 50px;
}
.role-group .col {
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  max-width: 180px;
}
.single-checkbox input[type="checkbox"] + label {
  padding-left: 18px;
}

.drop-file {
  position: relative;
  background: #f2f2f2;
  border-radius: 6px;
  border: dashed 2px #ddd;
  cursor: pointer;
  min-height: 180px;
  padding: 0 1rem;
  text-align: center;
}
.drop-file .custom-file-label {
  border-radius: 0;
  border: none;
  height: auto;
  padding: 0;
  margin: 0;
  color: var(--secondary);
  font-weight: 500;
  background: none;
  text-decoration: underline;
  text-align: center;
  position: static;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.drop-file .custom-file-label:focus,
.drop-file .custom-file-label:active {
  outline: none;
  box-shadow: none;
}
.drop-file .custom-file-label:after {
  display: none;
}
.drop-file input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
}
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.pagination {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.5rem;
}
.pagination .page-link {
  background: none !important;
  border: none;
  border-radius: 4px;
  margin: 2px;
  color: var(--black);
}
.pagination .page-item.active .page-link,
.pagination .page-link.active {
  background: var(--primary) !important;
  color: var(--white);
}

.pagination .page-link:hover {
  background: var(--white);
  color: var(--black);
}
.pagination .page-link.next,
.pagination .page-link.prev {
  background: var(--white);
  border: solid 1px var(--gray);
}

.nav-collapse-btn {
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 1004;
  cursor: pointer;
  width: 50px;
  height: 50px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  display: none;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
}
.nav-collapse-btn:focus {
  outline: none;
}
.is-open .nav-collapse-btn {
  right: 15px;
}
.nav-collapse-btn span,
.nav-collapse-btn span:before,
.nav-collapse-btn span:after {
  right: 0;
  content: "";
  position: absolute;
  display: block;
  width: 26px;
  height: 2px;
  background: var(--white);
  cursor: pointer;
  border-radius: 8px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.nav-collapse-btn span {
  top: 25px;
  right: 10px;
}
.nav-collapse-btn span:before {
  top: -8px;
  width: 10px;
}
.nav-collapse-btn span:after {
  bottom: -8px;
  width: 20px;
}
.is-open .nav-collapse-btn span {
  background-color: transparent;
}
.is-open .nav-collapse-btn span::before,
.is-open .nav-collapse-btn span::after {
  background: var(--white);
  width: 100%;
  height: 3px;
  top: 0;
}
.is-open .nav-collapse-btn span::before {
  transform: rotate(45deg);
}
.is-open .nav-collapse-btn span::after {
  transform: rotate(-45deg);
}
.backdrop,
.filter-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1003;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.4s;
  top: 0;
  left: 0;
  display: none;
}
.is-open .backdrop {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.4s;
}

.btn-search-close {
  font-size: 2rem;
  padding: 0;
  border: none;
  width: 50px;
  height: 40px;
  background: none;
  color: var(--white);
  position: fixed;
  top: 0;
  right: -50px;
  cursor: pointer;
  outline: 0;
  transition-duration: 0.4s;
  display: none;
}
.is-loader {
  overflow: hidden;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/loader.svg) no-repeat center
    rgba(255, 255, 255, 0.9);
  background-size: 60px;
  top: 0;
  left: 0;
  z-index: 999999;
  user-select: none;
  display: none;
}
.is-loader .loader {
  display: block;
}
.is-loader-boxed {
  overflow: hidden;
}
.loader-boxed {
  /* position: fixed; */
  width: 100%;
  height:100px;
  background: url(../../assets/img/loader.svg) no-repeat center;
  background-size: 60px;
  top: 0;
  left: 0;
  z-index: 999999;
  user-select: none;
  /* display: none; */
}
.is-loader-boxed .loader-boxed {
  display: block;
}
.card-body-submission {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.subm-item + .subm-item {
  padding-top: 20px;
  border-top: dotted 2px #ddd;
}
.bs-searchbox .form-control {
  height: 40px;
  padding: 0 1rem;
}

.col-fixed {
  position: sticky;
  left: 0;
  background: #fff;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.table-striped tbody tr:nth-of-type(2n + 1) td.col-fixed {
  background-color: #faf9f9;
}
.table-hover tbody tr:hover td.col-fixed {
  background-color: #ffeced;
}
.lead-filter .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: none;
  cursor: pointer;
}
.lead-filter .card-header:after {
  content: "-";
  margin-left: auto;
  font-size: 1.6rem;
}
.lead-filter .card-header.collapsed:after {
  content: "+";
}
.lead-filter .card-header.collapsed {
  border: none;
  border-radius: 0.8rem;
}
.card-table .bootstrap-select > .btn,
.card-filter .bootstrap-select > .btn {
  height: 40px;
  line-height: 38px;
}
.card-filter .form-control {
  height: 40px;
}
.btn-head-filter .fa-plus:before {
  content: "\f068";
}
.btn-head-filter.collapsed .fa-plus:before {
  content: "\f067";
}

.datetimepicker td,
.datetimepicker th {
  font-size: 12px;
  padding: 5px;
}

.highcharts-drillup-button {
  font-family: "Poppins, sans-serif";
}
.highcharts-drillup-button text {
  transform: translateX(4px);
}
.highcharts-drilldown-axis-label {
  fill: #333333 !important;
  text-decoration: none !important;
}
.highcharts-data-label text {
  text-decoration: none !important;
}
.fa-infos {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 4px;
}

.lead-filter.card-filter {
  position: fixed;
  top: 0;
  right: -280px;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.4s;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: var(--secondary);
  z-index: 1005;
  width: 280px;
  padding: 20px 20px 70px;
  border-radius: 0;
}
.lead-filter .card-body {
  min-height: inherit;
}
.lead-filter .select-filter,
.lead-filter .filter-item {
  background: var(--white);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
}
.lead-filter
  .select-filter
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn),
.lead-filter .filter-group .form-control,
.lead-filter
  .filter-group
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}
.lead-filter .filter-search-btn {
  width: 280px;
  position: fixed;
  bottom: 0;
  right: -280px;
  background: var(--secondary);
  padding: 10px 20px;
  box-shadow: 10px 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition-duration: 0.4s;
}
.is-search .lead-filter .filter-search-btn {
  right: 0;
}
.lead-filter .search-btn,
.lead-filter .filter-search-btn .btn {
  border: none;
  height: 50px;
  line-height: 50px;
  width: 100%;
  border-radius: 8px;
  background: var(--primary);
  color: var(--white);
  font-size: 1rem;
  margin: 0;
}
.lead-filter .search-btn svg {
  display: none;
}
.lead-filter .datepicker .dropdown-toggle:after {
  top: 40px;
  right: 16px;
}
.is-search {
  overflow: hidden;
}
.is-search .lead-filter .filter-group,
.is-search .lead-filter {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.is-search .filter-backdrop {
  display: block;
}
.is-search .lead-filter .btn-search-close {
  right: 280px;
  display: block;
}
.datepicker .dropdown-toggle::after {
  display: none;
}

/** Responsive **/
@media (min-width: 1200px) {
  .container {
    max-width: 1600px;
    padding: 0 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1240px) {
  #navbar .nav-item {
    margin-right: 2rem;
  }
}
@media (min-width: 1600px) {
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1200px) {
  #navbar {
    position: fixed;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 280px;
    top: 0;
    left: -280px;
    background: var(--secondary);
    transition-duration: 0.4s;
    visibility: hidden;
    opacity: 0;
    z-index: 1003;
  }
  .is-open #navbar {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  #navbar .navbar {
    display: block;
    padding: 50px 0 0;
  }
  #navbar .nav-item {
    margin-right: 0;
  }
  #navbar .nav-link {
    line-height: normal;
    padding: 1rem 1.5rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
  }
  #navbar .nav-link.dropdown-toggle:after {
    margin-left: auto;
  }
  #navbar .nav-link.active:before {
    width: 4px;
    height: 100%;
  }
  #navbar .dropdown-menu {
    position: static;
    min-width: 100%;
    border-radius: 0;
  }
  #header {
    padding-left: 40px;
  }
  .navbar-brand-logo img {
    max-width: 160px;
  }
  .container {
    max-width: 100%;
  }
  .nav-collapse-btn,
  .btn-search-close,
  .backdrop,
  .filter-backdrop {
    display: block;
  }
  .is-search .filter-backdrop {
    visibility: visible;
    opacity: 1;
    transition-duration: 0.4s;
  }
  .is-search .btn-search-close {
    right: 280px;
  }
  .is-open,
  .is-search {
    overflow: hidden;
  }
  .is-open .nav-collapse-btn {
    left: 220px;
  }
  .filter-nav {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .filter-nav .btn-radio label {
    white-space: nowrap;
  }
  .head-title h1 {
    white-space: nowrap;
    padding-right: 1.5rem;
  }
  .select-filter .bootstrap-select > .btn,
  .filter-group .form-control,
  .filter-group
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }
  .filter-group,
  .card-filter {
    position: fixed;
    top: 0;
    right: -280px;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.4s;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: var(--secondary);
    z-index: 1005;
    width: 280px;
    padding: 20px 20px 70px;
    border-radius: 0;
  }
  .card-filter .card-body {
    min-height: inherit;
  }
  .select-filter,
  .filter-item {
    background: var(--white);
    border-radius: 8px;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
  }
  .select-filter
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn),
  .filter-group .form-control {
    width: 100%;
  }
  .filter-search-btn {
    width: 280px;
    position: fixed;
    bottom: 0;
    right: -280px;
    background: var(--secondary);
    padding: 10px 20px;
    box-shadow: 10px 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition-duration: 0.4s;
  }
  .is-search .filter-search-btn {
    right: 0;
  }
  .filter-group .search-btn,
  .filter-search-btn .btn {
    border: none;
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-radius: 8px;
    background: var(--primary);
    color: var(--white);
    font-size: 1rem;
  }
  .filter-group .search-btn svg {
    display: none;
  }
  .filter-group .datepicker .dropdown-toggle:after {
    top: 40px;
    right: 16px;
  }
  .is-search .filter-group,
  .is-search .card-filter {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .hp-banner .carousel-indicators {
    bottom: 0;
  }
  .hp-banner .carousel-inner {
    padding-bottom: 50px;
  }
  .lead-filter .collapse:not(.show) {
    display: block;
  }
}
@media (max-width: 992px) {
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 2.4rem;
  }
  .h2,
  h2 {
    font-size: 1.8rem;
  }
  .h3,
  h3 {
    font-size: 1.2rem;
  }
  .h4,
  h4 {
    font-size: 0.85rem;
  }
  .h5,
  h5 {
    font-size: 0.725rem;
  }
  .brand-logo img {
    max-width: 180px;
  }
  .nav-collapse-btn {
    top: 5px;
  }
  #header {
    height: 60px;
  }
  .navbar-brand-logo img {
    max-width: 120px;
  }
  .dashboard-content {
    padding: 80px 0 30px;
  }
  .avtar-img {
    height: 40px;
    width: 40px;
  }
  .author-img {
    min-width: 30px;
    min-height: 30px;
  }
  .performer-head,
  .recourd-number {
    font-size: 0.7rem;
  }
  .btn-back {
    padding: 0 1rem 0 0;
  }
  .role-group {
    padding: 20px;
  }
  .card-counter .h1 {
    font-size: 1.5rem;
  }
  .btn {
    padding: 0 1rem;
  }
  .btn-add {
    position: fixed;
    bottom: 5%;
    right: 7px;
    font-size: 2rem;
    font-weight: 400;
    height: 40px;
    line-height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 100%;
    box-shadow: 0px 2px 5px #555;
  }
  .btn-add span {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
}
@media (max-width: 575px) {
  .per-pg {
    display: none;
  }
}
