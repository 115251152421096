@media (min-width: 768px) {
  .donut-width {
    flex: 0 0 32.5%;
    max-width: 32.5%;
  }
}

@media (max-width: 576px) {
    .donut-width  {
    flex: 0 0 98%;
    max-width: 98%;
  }
}
